import toInteger from "lodash/toInteger";
import isEmpty from "lodash/isEmpty";
import { serviceContainer } from "services/serviceContainer";

export type IRFIPointEntity = {
  id: number;
  applicationId: number;
  councilCreatedDate: string;
  councilSentDate: string;
  customerSubmittedDate: string;
  roundSubmittedByName: string;
  roundSubmittedById: number;
  councilSignedOffDate: string;
  requestDetails: string;
  requestPersonName: string;
  requestPersonEmail: string;
  requestPersonPhone: string;
  responsePersonName: string;
  responseLastModifiedDate: string;
  responseDetails: string;
  rfiPointGuid: string;
  rfiPointType: RFIPointType;
  rfiRound: number;
  rfiRoundLegacy: string;
  rfiLocation: string;
  displayOrderInRound: number;
  documentContainerId: number;
  documentCategoryId: number;
  documentNames: string[];
  rfiTeam: string;
  rfiBuilding: string;
  rfiPointStatus: RFIPointStatus;
  rfiTitle: string;
  previousRfiPointGuid: string;
  nextRfiPointGuid: string;
};

export enum RFIPointType {
  Vetting = "Vetting",
  Consent = "Consent",
  CCC = "CCC",
}

export enum RFIDerivedStatus {
  NotResponded = "NotResponded",
  Responded = "Responded",
  Submitted = "Submitted",
  Processed = "Processed",
  Unknown = "Unknown",
}

export enum RFIPointStatus {
  Resolved = "RESOLVED",
  NotResolved = "NOT_RESOLVED",
  Incomplete = "INCOMPLETE",
  Submitted = "SUBMITTED",
  NotSubmitted = "NOT_SUBMITTED",
  NotSpecified = "NOT_SPECIFIED",
}

export const RFIPointUtil = {
  getRFIDerivedStatus: (rfiPoint: IRFIPointEntity): RFIDerivedStatus => {
    if (rfiPoint.councilSignedOffDate) {
      return RFIDerivedStatus.Processed;
    }
    if (rfiPoint.customerSubmittedDate) {
      return RFIDerivedStatus.Submitted;
    }
    if (rfiPoint.responseDetails) {
      return RFIDerivedStatus.Responded;
    }

    return RFIDerivedStatus.NotResponded;
  },
};

export type RFIPointStatusMeta = {
  label: string;
  icon: string;
};

export const RFIPointTypeUtil = {
  parse: (pointType: string): RFIPointType | null => {
    switch (pointType) {
      case "Vetting":
        return RFIPointType.Vetting;
      case "Consent":
        return RFIPointType.Consent;
      case "CCC":
        return RFIPointType.CCC;
      default:
        return null;
    }
  },
  getDisplayName: (rfiPointType: RFIPointType) => {
    const t = serviceContainer.cradle.i18n.t;
    switch (rfiPointType) {
      case RFIPointType.Vetting:
        return t("Lodgement");
      case RFIPointType.Consent:
        return t("Assessment");
      case RFIPointType.CCC:
        return t("Certificates");
      default:
        return "";
    }
  },
  parseRFIPointStatus: (rfiPointStatus: string): RFIPointStatus => {
    switch (rfiPointStatus) {
      case "RESOLVED":
        return RFIPointStatus.Resolved;
      case "NOT_RESOLVED":
        return RFIPointStatus.NotResolved;
      case "INCOMPLETE":
        return RFIPointStatus.Incomplete;
      case "SUBMITTED":
        return RFIPointStatus.Submitted;
      case "NOT_SUBMITTED":
        return RFIPointStatus.NotSubmitted;
      default:
        return RFIPointStatus.NotSpecified;
    }
  },
};

export const RFIRoundUtil = {
  parse: (rfiRoundValue: string): number => {
    const matchResult = rfiRoundValue.match(/(\d+)/);

    if (!Array.isArray(matchResult) || isEmpty(matchResult)) {
      return 0;
    }

    return toInteger(matchResult[0]);
  },
  getRFIRoundStatus: (rfiPointsInRound: IRFIPointEntity[]) => {
    const rfiPointStatuses = rfiPointsInRound.map(RFIPointUtil.getRFIDerivedStatus);
    if (rfiPointStatuses.every((status) => status === RFIDerivedStatus.Processed)) {
      return RFIDerivedStatus.Processed;
    }
    if (rfiPointStatuses.every((status) => status === RFIDerivedStatus.Submitted)) {
      return RFIDerivedStatus.Submitted;
    }
    if (
      rfiPointStatuses.every(
        (status) =>
          status === RFIDerivedStatus.Responded ||
          status === RFIDerivedStatus.Processed ||
          status === RFIDerivedStatus.Submitted
      )
    ) {
      return RFIDerivedStatus.Responded;
    }

    return RFIDerivedStatus.NotResponded;
  },
};
