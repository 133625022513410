import React from "react";
import { useTranslation } from "react-i18next";
import FlexBox from "components/FlexBox/FlexBox";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  selectParticipantTypeOccurrences,
  selectVisibleParticipantTypes,
} from "store/domain-data/participant-type/participantType";
import styled, { css } from "styled-components/macro";
import {
  getParticipantName,
  IParticipantTypeApplicationEntity,
  PARTICIPANT_TYPE_NAMES,
  ParticipantTypeExtraNumber,
  rankAccessLevel,
} from "models/Participant.model";
import ParticipantAccessRadios from "components/ParticipantEditDialog/ParticipantAccessRadios";
import { useToast } from "hooks/useToast";
import { useStore } from "react-redux";
import {
  selectCurrentParticipant,
  selectOriginalParticipantTypes,
  selectParticipantType,
  setAccessLevel,
  unselectParticipantType,
} from "store/app-state/participant-buffer/participantBuffer";
import ParticipantHeadlineSimple from "components/ParticipantEditDialog/ParticipantHeadlineSimple";
import ParticipantChangeTypeConfirmationDialog from "components/ParticipantChangeTypeConfirmationDialog/ParticipantChangeTypeConfirmationDialog";
import ParticipantFormParticipantTypeTag from "components/ParticipantEditDialog/ParticipantFormParticipantTypeTag";
import isEmpty from "lodash/isEmpty";

const ParticipantTypeAndAccessForm: React.FC = () => {
  // Common
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { toastSuccess } = useToast();
  const store = useStore();

  // Derived
  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));
  const originalParticipantTypes = useStoreSelector((state) => selectOriginalParticipantTypes(state));

  const participantTypes = useStoreSelector((state) => selectVisibleParticipantTypes(state, originalParticipantTypes));

  const [
    lastParticipantTypeSelection,
    setLastParticipantTypeSelection,
  ] = React.useState<IParticipantTypeApplicationEntity | null>(null);

  const [isChangeParticipantTypeDialogOpen, setIsChangeParticipantTypeDialogOpen] = React.useState(false);

  const participantName = React.useMemo(() => {
    const name = getParticipantName(currentParticipant);

    if (isEmpty(name)) {
      return t("this person");
    }
    return name;
  }, [currentParticipant, t]);

  const isParticipantTypeSelected = React.useCallback(
    (participantType: IParticipantTypeApplicationEntity) => {
      return currentParticipant.participantTypes.some((selectedType) => selectedType.name === participantType.name);
    },
    [currentParticipant.participantTypes]
  );

  const handleAddParticipantType = React.useCallback(
    (participantType?: IParticipantTypeApplicationEntity) => {
      const currentParticipantType = participantType || lastParticipantTypeSelection;

      if (!currentParticipantType) {
        return;
      }

      // When participant type has a higher minimum access then the current selection, increase accordingly.
      if (
        currentParticipantType.minimumAccessLevel &&
        rankAccessLevel(currentParticipantType.minimumAccessLevel) > rankAccessLevel(currentParticipant.accessLevel)
      ) {
        dispatch(setAccessLevel(currentParticipantType.minimumAccessLevel));
        toastSuccess(t(`Participant access has been increased`));
      }

      const isRegisteredOwnerType = currentParticipantType.name === PARTICIPANT_TYPE_NAMES.REGISTERED_OWNER;
      if (isRegisteredOwnerType) {
        const additionalOwnerType = currentParticipant.participantTypes.find(
          (selectedType) => selectedType.name === PARTICIPANT_TYPE_NAMES.ADDITIONAL_OWNER
        );
        if (additionalOwnerType) {
          dispatch(unselectParticipantType(additionalOwnerType));
        }
      }
      dispatch(selectParticipantType(currentParticipantType));
      setLastParticipantTypeSelection(null);
    },
    [
      lastParticipantTypeSelection,
      currentParticipant.accessLevel,
      currentParticipant.participantTypes,
      toastSuccess,
      dispatch,
      t,
    ]
  );

  const addSelectedParticipantType = React.useCallback(
    (participantType: IParticipantTypeApplicationEntity) => {
      const participantTypeOccurrences = selectParticipantTypeOccurrences(store.getState(), {
        applicationId: currentParticipant.applicationId,
        participantTypeName: participantType.name,
      });

      if (
        // Only one participant has this type
        participantTypeOccurrences.participants.length === 1 &&
        // The participant with this type is not the current participant
        participantTypeOccurrences.participants[0].id !== currentParticipant.id &&
        (participantType.number === ParticipantTypeExtraNumber.ExactlyOne ||
          participantType.number === ParticipantTypeExtraNumber.MaximumOne)
      ) {
        setIsChangeParticipantTypeDialogOpen(true);
      } else {
        handleAddParticipantType(participantType);
      }
    },
    [
      currentParticipant.applicationId,
      currentParticipant.id,
      store,
      handleAddParticipantType,
      setIsChangeParticipantTypeDialogOpen,
    ]
  );

  const removeSelectedParticipantType = React.useCallback(
    (participantType: IParticipantTypeApplicationEntity) => {
      dispatch(unselectParticipantType(participantType));
      setLastParticipantTypeSelection(null);
    },
    [dispatch]
  );

  const toggleParticipantType = React.useCallback(
    (participantType: IParticipantTypeApplicationEntity) => {
      setLastParticipantTypeSelection(participantType);

      if (isParticipantTypeSelected(participantType)) {
        removeSelectedParticipantType(participantType);
      } else {
        addSelectedParticipantType(participantType);
      }
    },
    [
      setLastParticipantTypeSelection,
      isParticipantTypeSelected,
      addSelectedParticipantType,
      removeSelectedParticipantType,
    ]
  );

  const handleCloseChangeParticipantTypeDialog = React.useCallback(
    async (confirmed: boolean) => {
      if (confirmed && lastParticipantTypeSelection) {
        handleAddParticipantType();
        toastSuccess(t(`Participant type has been reassigned`));
      }

      setIsChangeParticipantTypeDialogOpen(false);
    },
    [lastParticipantTypeSelection, t, toastSuccess, handleAddParticipantType]
  );

  return (
    <React.Fragment>
      <FlexBox
        data-testid="EditParticipantStepTypesAccess"
        direction="column"
        paddingTop={6}
        paddingBottom={8}
        paddingX={12}
        spacing={4}
      >
        <ParticipantHeadlineSimple />

        <StyledHeader>{t(`How is {{name}} involved?`, { name: participantName })}</StyledHeader>

        <StyledTagsContainer>
          {participantTypes.map((participantType) => (
            <ParticipantFormParticipantTypeTag
              key={participantType.name}
              participantTypeId={participantType.id}
              participantBuffer={currentParticipant}
              onToggle={() => toggleParticipantType(participantType)}
            />
          ))}
        </StyledTagsContainer>

        <ParticipantAccessRadios />
      </FlexBox>

      {lastParticipantTypeSelection && (
        <ParticipantChangeTypeConfirmationDialog
          applicationId={currentParticipant.applicationId}
          participantFirstName={currentParticipant.firstName}
          participantLastName={currentParticipant.lastName}
          businessName={currentParticipant.organisation}
          participantTypeName={lastParticipantTypeSelection.name}
          participantTypeDisplayName={lastParticipantTypeSelection.displayName}
          isOpen={isChangeParticipantTypeDialogOpen}
          onClose={handleCloseChangeParticipantTypeDialog}
        />
      )}
    </React.Fragment>
  );
};

const StyledHeader = styled.span(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 14px;
    color: ${theme.palette.objective.dark.fiordland};
  `
);

const StyledTagsContainer = styled.div(
  ({ theme }) => css`
    margin-top: 5px;
    display: flex;
    ${theme.mixins.flexGap("10px")}
    flex-wrap: wrap;
  `
);

export default ParticipantTypeAndAccessForm;
