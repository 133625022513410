import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Select } from "@material-ui/core";
import FlexBox from "components/FlexBox/FlexBox";
import Spacer from "components/Spacer/Spacer";
import styled, { css } from "styled-components/macro";
import {
  accessLevelMetaData,
  getParticipantName,
  parseParticipantAccessLevel,
  ParticipantAccessLevel,
  rankAccessLevel,
} from "models/Participant.model";
import FontIcon from "components/FontIcon/FontIcon";
import SelectOption from "components/ParticipantEditDialog/SelectOption";
import isEmpty from "lodash/isEmpty";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectAllParticipantTypeEntities } from "store/domain-data/participant-type/participantType";
import { setAccessLevel, selectCurrentParticipant } from "store/app-state/participant-buffer/participantBuffer";

const ParticipantAccessRadios: React.FC = () => {
  // Common
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  // Derived
  const [minimumAllowedAccessLevel, setMinimumAllowedAccessLevel] = React.useState(ParticipantAccessLevel.Unassigned);
  const accessOptions = React.useMemo(() => {
    return Object.entries(accessLevelMetaData)
      .map(([, accessLevel]) => accessLevel)
      .filter((accessLevel) => accessLevel.value !== ParticipantAccessLevel.Unassigned);
  }, []);

  const allParticipantTypes = useStoreSelector((state) => selectAllParticipantTypeEntities(state));

  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));

  const handleMinimumAllowedAccessLevel = React.useCallback(() => {
    // Start Unassigned and increase as we find selected participant types with higher minimum access
    let currMinimumAllowedAccess = ParticipantAccessLevel.Unassigned;

    if (!isEmpty(currentParticipant.participantTypes)) {
      // Determine highest minimum access level amongst the selected participant types
      currentParticipant.participantTypes.forEach((selectedType) => {
        const selectedTypeMinAccess =
          allParticipantTypes.find((type) => type.name === selectedType.name)?.minimumAccessLevel ||
          ParticipantAccessLevel.Unassigned;

        if (rankAccessLevel(currMinimumAllowedAccess) < rankAccessLevel(selectedTypeMinAccess)) {
          currMinimumAllowedAccess = selectedTypeMinAccess;
        }
      });
    }

    setMinimumAllowedAccessLevel(currMinimumAllowedAccess);
  }, [allParticipantTypes, currentParticipant.participantTypes]);

  const handleChangeParticipantAccess = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      dispatch(setAccessLevel(event.target.value as ParticipantAccessLevel));
    },
    [dispatch]
  );

  const checkAccessOptionDisabled = React.useCallback(
    (optionValue: string) => {
      const optionAccessLevel = parseParticipantAccessLevel(optionValue);
      return rankAccessLevel(optionAccessLevel) < rankAccessLevel(minimumAllowedAccessLevel);
    },
    [minimumAllowedAccessLevel]
  );

  // Effects
  React.useEffect(() => {
    handleMinimumAllowedAccessLevel();
  }, [allParticipantTypes, currentParticipant.participantTypes, handleMinimumAllowedAccessLevel]);

  return (
    <FlexBox direction="column" paddingTop={6} spacing={2}>
      <StyledHeader>
        {t(`How much access should {{name}} have?`, { name: getParticipantName(currentParticipant) })}
      </StyledHeader>
      <StyledSelect
        data-testid={"AccessLevelSelect"}
        id="access-level-select"
        variant="outlined"
        value={currentParticipant.accessLevel}
        onChange={handleChangeParticipantAccess}
      >
        {accessOptions.map((option) => (
          <SelectOption
            key={option.value}
            title={t(option.title)}
            icon={option.icon}
            value={option.value as string}
            disabled={checkAccessOptionDisabled(option.value as string)}
          >
            <FlexBox>
              <StyledFontIcon name={option.icon} />
              <Spacer x={2} />
              <Box fontSize={16} fontWeight={600}>
                {option.title}
              </Box>
            </FlexBox>
          </SelectOption>
        ))}
      </StyledSelect>
    </FlexBox>
  );
};

const StyledHeader = styled.span(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 14px;
    color: ${theme.palette.objective.dark.fiordland};
  `
);

const StyledSelect = styled(Select)(
  ({ theme }) => css`
    .MuiSelect-root {
      font-size: 16px;
      color: ${theme.palette.objective.dark.night};
    }
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    .MuiSelect-root {
      padding: 12px 12px 10px;
    }
  `
);
export default ParticipantAccessRadios;
